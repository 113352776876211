import ChannelGroupContextMenu from "@/components/ContextMenus/ChannelGroupContextMenu";
import Loading from "@/components/Loading/Index";
import Locator from "@/locator";
import { DataContext } from "@/models/DataProvider";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Stack,
  useTheme,
} from "@mui/material";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useContext, useState, memo } from "react";
import { useParams } from "react-router-dom";
import Folder from "../Icons/Folder";
import { WorkspaceFeedGroup } from "./WorkspaceChannelList";
import WorkspaceChannelListItem from "./WorkspaceChannelListItem";
import { UnreadsContext } from "@/models/UnreadsContextProvider";
import equal from "fast-deep-equal/react";

const WorkspaceChannelGroup = ({
  feedGroup,
  joined,
  membershipId,
  markAllChannelsAsRead,
  reRenderList,
}: {
  feedGroup: WorkspaceFeedGroup;
  joined: boolean;
  membershipId?: string;
  markAllChannelsAsRead?: () => void;
  reRenderList?: () => void;
}) => {
  const { currentFeedId } = useContext(DataContext);
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const theme = useTheme();
  const [joining, setJoining] = useState<boolean>(false);
  const { ampli } = useContext(TrackingContext);
  const { unreadFeedIds } = useContext(UnreadsContext);
  const [feedGroupShown, saveFeedGroupShown] = useLocalStorage(
    `${feedGroup.id}-group-toggle`,
    false,
  );

  const feedGroupIds = feedGroup?.joinedFeeds?.map((feed) => feed.id);
  const groupIncludesCurrentFeed = feedGroupIds?.includes(currentFeedId);
  const groupUnreadFeeds = unreadFeedIds?.filter((ufi) =>
    feedGroupIds?.includes(ufi),
  );

  const hasUnread = groupUnreadFeeds?.length > 0;

  const handleGroupClick = () => {
    const toggledGroup = !feedGroupShown;
    if (toggledGroup) {
      ampli.groupExpand({ workspaceId });
    } else {
      ampli.groupCollapse({ workspaceId });
    }
    saveFeedGroupShown(!feedGroupShown);
  };

  return (
    <ListItem
      key={`${workspaceId}-${feedGroup.id}`}
      data-testid={Locator.workspaceNav.channels.list.testId(feedGroup.id)}
      aria-label={Locator.workspaceNav.channels.list.item}
      sx={{
        fontSize: "1.125rem",
        fontWeight: 500,
        px: 0,
        "&:first-of-type": {
          paddingTop: 0,
        },
        py: 0,
      }}
    >
      <ChannelGroupContextMenu
        markAllChannelsAsRead={markAllChannelsAsRead}
        group={feedGroup}
        membershipId={membershipId}
        joiningHandler={setJoining}
        setParentDisabled={(value) => setJoining(() => value)}
      >
        <Stack>
          <ListItemButton
            disabled={joining}
            onClick={handleGroupClick}
            selected={groupIncludesCurrentFeed}
            disableRipple={true}
            sx={{
              width: "100%",
              borderRadius: "8px",
              color: joined
                ? theme.palette.primary.main
                : theme.palette.secondary.light,
              "&.Mui-selected, &.Mui-disabled": {
                color: theme.palette.primary.main,
                background: theme.palette.secondary.main,
              },
              "&:focus-visible": {
                outline: "none",
                boxShadow: "none",
              },
              wordBreak: "break-word",
              p: 1.5,
              lineHeight: "24px",
              height: 48,
              gap: 1,
            }}
          >
            {feedGroupShown ? (
              <KeyboardArrowUp role="img" />
            ) : (
              <KeyboardArrowDown role="img" />
            )}
            <Stack sx={{ pl: 0.5 }}>
              <Folder role="img" />
            </Stack>
            {feedGroup?.label}
            {feedGroup?.joined && hasUnread && (
              <Chip
                aria-label={Locator.workspaceNav.channels.list.unreadCount}
                data-testid={Locator.workspaceNav.channels.list.groups.unreadCountId(
                  (groupUnreadFeeds?.length || 0).toString(),
                )}
                sx={{
                  background: theme.palette.brand.secondary.main,
                  width: 44,
                  height: 28,
                  fontSize: "1.125rem",
                  fontWeight: 500,
                  "& .MuiChip-label": {
                    p: 0,
                  },
                  ml: "auto",
                }}
                label={groupUnreadFeeds?.length || 0}
              />
            )}
            {joining ? (
              <Box sx={{ ml: "auto" }}>
                <Loading variant="disabled" baseSize={6} />
              </Box>
            ) : null}
          </ListItemButton>
          <Collapse in={feedGroupShown} timeout="auto">
            <List
              component="div"
              disablePadding
              sx={{
                height: "100%",
                pl: 4,
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                pt: 1.5,
              }}
            >
              {feedGroup?.feeds?.map((feed) => (
                <WorkspaceChannelListItem
                  setJoined={() => reRenderList()}
                  feed={feed}
                  joined={feed.joined}
                  membershipId={membershipId}
                  feedId={feed?.id}
                  key={feed?.id}
                />
              ))}
            </List>
          </Collapse>
        </Stack>
      </ChannelGroupContextMenu>
    </ListItem>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    equal(nextProps.feedGroup, prevProps.feedGroup) &&
    nextProps.joined === prevProps.joined
  );
};

export default memo(WorkspaceChannelGroup, areEqual);
