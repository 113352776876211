import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useBeforeUnload, useParams } from "react-router-dom";
import {
  WsItem,
  WsAudioEncoding,
  WsAudioQueueItem,
} from "web-client/api/data-contracts";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { browserIsSafari, isBrowser } from "@/utils";
import { useElectric } from "@/electric/ElectricWrapper";
import { useLiveQuery } from "electric-sql/react";
import { AudioEncoding } from "@/generated/client";
import { targetCodecForBrowser } from "@/utils/audio";
import { selectAudio } from "@/utils/contentSelection";
import { AllPreferredLanguage, PreferredLanguage } from "./languages";
import {
  upsertWsAudioQueueItem,
  deleteWsAudioQueueItem,
  deleteAllWsAudioQueueItems,
} from "@/data/workspace";
import { handsFreeEnabledForWorkspace } from "@/data/workspaceConfig";
import usePrevious from "@/hooks/usePrevious";
import cuid from "cuid";
import { useFlags } from "launchdarkly-react-client-sdk";

function createSourceElement(filename: string) {
  const contentType = "audio/ogg";
  const src = filename;
  const srcElement = document.createElement("source");
  srcElement.type = contentType;
  srcElement.src = src;
  return srcElement;
}

function createSafariSourceElement(filename: string) {
  const contentType = `audio/x-caf; codecs="opus"`;
  const src = filename;
  const srcElement = document.createElement("source");
  srcElement.type = contentType;
  srcElement.src = src;
  return srcElement;
}

const loadAudioLibrary = async () => {
  if (document !== undefined) {
    try {
      console.log("loading audio library");
      // @ts-ignore
      return await import("@storyboard-fm/audio-core-library").then(
        (module) => module,
      );
    } catch (e) {
      console.error("loadAudioLibrary", e);
    }
  } else {
    console.log("document is undefined, cannot load audio library");
  }
};

const audioElement = new Audio();

export type AudioState = {
  queuePlaying: boolean;
  activeQueueItemId?: string;
  addToAudioQueue: (audioEncodings: WsAudioEncoding[]) => Promise<void>;
  removeItemFromQueue: (itemId: string) => Promise<void>;
  audioElement?: HTMLAudioElement;
  audioCtx?: AudioContext;
  handsFreeModeEnabled?: boolean;
  playbackEnabled?: boolean;
  getPlayQueue: (_: {
    items: WsItem[];
    currentId: string;
  }) => WsItem[];
  setQueue: (items: WsItem[]) => void;
  setHandsFreeMode: (handsFree?: boolean) => void;
  setPlaybackEnabled: (playbackEnabled: boolean) => void;
  setQueuePlaying: (playing?: boolean) => void;
  setActiveQueueItemId: (id: string) => void;
  playQueue: () => void;
  resetQueue: () => void;
  pauseQueue: () => void;
  setManualPlay: (value: boolean) => void;
};

export const AudioAppContext = createContext<AudioState>({
  getPlayQueue: (_ = { items: [], currentId: "" }) => [],
  pauseQueue: () => { },
  playQueue: () => { },
  addToAudioQueue: async () => { },
  removeItemFromQueue: async () => { },
  audioElement,
  queuePlaying: false,
  resetQueue: () => { },
  setHandsFreeMode: () => { },
  setPlaybackEnabled: () => { },
  setActiveQueueItemId: () => { },
  setQueuePlaying: () => { },
  setQueue: () => { },
  setManualPlay: () => { },
});

const mimeTypeOverride = {
  opus: "audio/ogg; codecs='opus'",
  caf: "audio/x-caf; codecs='opus'",
};

function sourcesFromItem(audioSources: AudioEncoding[]) {
  const sourceUrls = [];
  const opus = audioSources.filter((c) => c.codec === "opus")[0];
  const caf = audioSources.filter((c) => c.codec === "caf")[0];
  const mp3 = audioSources.filter((c) => c.codec === "mp3")[0];
  for (const af of [mp3, opus, caf]) {
    if (af) {
      sourceUrls.push({
        src: af.url,
        type:
          af.codec in mimeTypeOverride
            ? mimeTypeOverride[af.codec]
            : af.mimeType,
      });
    }
  }
  const sources = sourceUrls.map((src) => {
    const source = document.createElement("source");
    source.setAttribute("src", src.src);
    if (src.type) {
      source.setAttribute("type", src.type);
    }
    return source;
  });
  return sources;
}

type Props = {
  children: React.ReactNode | React.ReactNode[];
  handsFreeModeFlag: boolean;
};

const AudioAppContextProvider = ({ children, handsFreeModeFlag }: Props) => {
  const { ampli } = useContext(TrackingContext);
  const { db } = useElectric();

  const [audioCtx, setAudioCtx] = React.useState<AudioContext>();
  const [playbackEnabled, setPlaybackEnabled] = React.useState<boolean>(true);
  const [queuePlaying, setQueuePlaying] = React.useState<boolean>(false);
  const [activeQueueItemId, setActiveQueueItemId] = React.useState<string>("");
  const [manualPlay, setManualPlay] = React.useState<boolean>(false);
  const [handsFreeModeEnabled, setHandsFreeModeEnabled] =
    React.useState<boolean>(false);
  const { feedId: currentFeedId } = useParams();
  const { handsFreePlayback } = useFlags();

  const { results: audioQueue } = useLiveQuery(
    db.audio_queue_item.liveMany({
      orderBy: {
        createdAt: "asc",
      },
    }),
  );

  const prevQueueItem = usePrevious(audioQueue?.[0]) as WsAudioQueueItem;

  const clearSources = useCallback(() => {
    if (audioElement.hasChildNodes()) {
      const children = audioElement.childNodes;
      for (const child of children) {
        audioElement.removeChild(child);
      }
    }
    if (browserIsSafari(navigator.userAgent)) {
      audioElement.src = "";
    }
  }, []);

  const resetQueue = useCallback(async () => {
    console.log("resetQueue");
    setActiveQueueItemId("");
    await deleteAllWsAudioQueueItems(db);
    if (audioElement) {
      console.log("Pausing audio");
      audioElement.pause();
      audioElement.currentTime = 0;
      clearSources();
      audioElement.load();
    }
  }, [db, clearSources]);

  useEffect(() => {
    loadAudioLibrary()
      ?.then(() => {
        const audioCtx = new AudioContext();
        console.log("audioCtx", audioCtx);
        audioCtx.toggle();
        setAudioCtx(audioCtx);
      })
      .catch((e) => console.error("Error loading audio library", e));
  }, []);

  const playNextInQueue = useCallback(async () => {
    // delete the first item in the queue
    // triggers playback of the next item in the queue table
    await deleteWsAudioQueueItem(db, audioQueue?.[0]);
  }, [db, audioQueue]);

  const removeItemFromQueue = useCallback(
    async (itemId: string) => {
      const queueItem = audioQueue?.find((item) => item.itemId === itemId);
      if (queueItem) {
        await deleteWsAudioQueueItem(db, queueItem);
      }
    },
    [db, audioQueue],
  );

  const playQueue = useCallback(() => {
    if (!audioElement) return;
    audioElement.muted = false;
    const play = audioElement.play();
    if (play !== undefined) {
      play
        .then((_) => {
          // automatic playback started
          setQueuePlaying(true);
        })
        .catch((error) => {
          // Auto-play was prevented
          setQueuePlaying(false);
        });
    }
  }, []);

  const pauseQueue = useCallback(() => {
    if (!audioElement) return;
    audioElement.pause();
    setQueuePlaying(false);
  }, []);

  useEffect(() => {
    if (audioElement) {
      audioElement.onended = () => {
        setQueuePlaying(false);
        clearSources();
        if (audioQueue?.length > 0) {
          playNextInQueue();
        }
      };
      audioElement.oncanplaythrough = () => {
        const item = audioQueue?.[0];
        if (manualPlay) {
          ampli.playRecord({ itemId: item?.itemId, feedId: currentFeedId });
        } else {
          ampli.autoplayRecord({
            itemId: item?.itemId,
            feedId: currentFeedId,
            handsFree: handsFreeModeEnabled,
          });
        }

        setManualPlay((prev) => (prev ? false : prev));

        console.warn("canplaythrough fired --- about to play", audioElement);
        if (playbackEnabled) {
          // Only play once we are actually ready to play
          playQueue();
        }
      };
    }
  }, [
    playQueue,
    audioQueue,
    ampli,
    playNextInQueue,
    playbackEnabled,
    handsFreeModeEnabled,
    currentFeedId,
    manualPlay,
    clearSources,
  ]);

  const createNewQueueItem = (item: WsItem) => {
    const queueItem: WsAudioQueueItem = {
      id: cuid(),
      itemId: item.id,
      createdAt: new Date(Date.now()).toISOString(),
    };
    return queueItem;
  };

  const setQueue = async (items: WsItem[]) => {
    // clear the previous queue
    await deleteAllWsAudioQueueItems(db);
    items.forEach(async (item) => {
      const queueItem = createNewQueueItem(item);
      await upsertWsAudioQueueItem(db, queueItem);
    });
  };

  const getPlayQueue = useCallback(
    ({
      items,
      currentId,
    }: {
      items: WsItem[];
      currentId: string;
    }) => {
      if (!items.length) {
        return items;
      }

      let start = 0;

      while (start < items.length) {
        if (items[start]?.id === currentId) {
          break;
        }
        start++;
      }

      return items.slice(start);
    },
    [],
  );

  const loadAudioSources = useCallback(async () => {
    // console.log("loadAudioSources");
    try {
      const queueItem = audioQueue?.[0];
      // if we have an item already that matches the prev first item in the queue,
      // then skip so it does not need to reload the source
      if (prevQueueItem && prevQueueItem?.id === queueItem.id) {
        return;
      }
      const myAccount = await db.account.findFirst({
        where: {
          mine: 1,
        },
      });

      const item = await db.item.findFirst({
        where: {
          id: queueItem?.itemId,
        },
      });

      const preferredLanguage = (myAccount?.preferredLanguage ||
        "none") as PreferredLanguage;

      if (!item) {
        console.log("No item");
        return;
      }

      const audioContents = await db.audio_encoding.findMany({
        where: {
          contentId: item?.contentId,
          codec: targetCodecForBrowser(),
        },
      });

      const originalTranscription = await db.transcription.findFirst({
        where: {
          contentId: item?.contentId,
          translatedFrom: null,
        },
      });
      const inputLanguage =
        originalTranscription?.language as AllPreferredLanguage;

      console.log({
        audioContents,
      });
      const audioContent = selectAudio(
        preferredLanguage,
        audioContents,
        inputLanguage,
      );

      console.log({
        preferredLanguage,
        audioContent,
      });

      if (audioContent.length === 0) {
        console.log("No audio sources, skipping item");
        playNextInQueue();
        return;
      }

      const sources = sourcesFromItem(audioContent);
      let srcElement;
      let srcUrl;

      if (browserIsSafari(navigator.userAgent)) {
        // Safari source element
        try {
          srcUrl = sources.filter((cs) => cs.src?.endsWith("caf") === true)?.[0]
            .src;
        } catch (err) {
          srcUrl = sources?.[0].src?.replace(".mp3", ".caf");
        }
        srcElement = createSafariSourceElement(srcUrl);
      } else {
        // Source element for all non-Safari browsers
        srcUrl = sources[0]?.src;
        srcElement = createSourceElement(srcUrl);
      }

      try {
        clearSources();
        // No matter what UA we are we still append the source element
        audioElement.appendChild(srcElement);

        if (browserIsSafari(navigator.userAgent)) {
          audioElement.preload = "none"; // usually Safari ignores what you set here anyway
          // Safari needs this to be set explicitly as well
          audioElement.src = srcUrl;
        } else {
          // here we test only appending the source element for non-Safari
          audioElement.appendChild(srcElement);
        }
        // muted before audio is loaded for safari
        audioElement.muted = true;
        audioElement.load();
      } catch (e) {
        console.error("** err thrown during loadAudioSources func", e);
      }
    } catch (e) {
      console.error("Could not load audio", e);
    }
  }, [db, audioQueue, clearSources, playNextInQueue, prevQueueItem]);

  const addToAudioQueue = useCallback(
    async (audioEncodings: WsAudioEncoding[]) => {
      // console.log("addToAudioQueue");
      if (!audioEncodings || audioEncodings?.length === 0 || !handsFreePlayback)
        return;

      const myAccount = await db.account.findFirst({
        where: {
          mine: 1,
        },
      });

      const contentIds = audioEncodings?.map((ae) => ae.contentId);

      const items = await db.item.findMany({
        where: {
          contentId: {
            in: contentIds,
          },
        },
      });

      const displayArtifact = await db.display_artifact.findFirst({
        where: {
          contentId: {
            in: contentIds,
          },
        },
      });

      const originalTranscription = await db.transcription.findFirst({
        where: {
          contentId: { in: contentIds },
          translatedFrom: null,
        },
      });

      const inputLanguage =
        originalTranscription?.language as AllPreferredLanguage;

      // if we have a display artifact then its considered long form audio
      // skip adding it to a queue
      if (displayArtifact) {
        return;
      }

      const isMine =
        items?.filter((i) => i.accountId === myAccount?.id).length > 0;

      if (isMine || items?.length === 0) return;

      const item = items[0];
      if (item?.isSilent) return;

      const itemWorkspaceFeed = await db.feed.findFirst({
        where: {
          id: item?.feedId,
        },
      });

      let handsFreeMode = false;
      if (handsFreeEnabledForWorkspace(itemWorkspaceFeed?.workspaceId)) {
        // check if hands free mode is enabled for the workspace based on the items feed
        handsFreeMode = JSON.parse(
          window.localStorage.getItem("handsFreeMode"),
        );
      }

      if (!handsFreeMode) return;

      const itemAlreadyInQueue = await db.audio_queue_item.findFirst({
        where: {
          itemId: item?.id,
        },
      });

      // if we have a queue item already with a matching id then skip
      if (itemAlreadyInQueue) {
        return;
      }

      // The item is not the users, hands free mode enabled, and we have playable audio content
      let hasPlayableFile: AudioEncoding;
      if (browserIsSafari(navigator.userAgent)) {
        hasPlayableFile = audioEncodings.find((ac) => ac.codec === "caf");
      } else {
        hasPlayableFile = audioEncodings.find((ac) => ac.codec === "opus");
      }

      const preferredLanguage = (myAccount?.preferredLanguage ||
        "none") as PreferredLanguage;

      const isOriginalTTSMessage =
        hasPlayableFile?.generatedVoice &&
        hasPlayableFile?.translatedFrom === null;

      if (hasPlayableFile) {
        if (
          (hasPlayableFile?.language === null &&
            preferredLanguage === "none") ||
          (hasPlayableFile.language === null &&
            inputLanguage === preferredLanguage) ||
          (isOriginalTTSMessage && preferredLanguage === "none") ||
          hasPlayableFile?.language === preferredLanguage
        ) {
          const newItem = createNewQueueItem(item);
          await upsertWsAudioQueueItem(db, newItem);
          console.log("new item added to playback queue", newItem);
        }
      }
    },
    [db, createNewQueueItem, handsFreePlayback],
  );

  const setHandsFreeMode = useCallback((handsFree: boolean) => {
    if (isBrowser()) {
      window.localStorage.setItem("handsFreeMode", `${handsFree}`);
    }
    setHandsFreeModeEnabled(handsFree);
  }, []);

  const getHandsFreeMode = useCallback(() => {
    const savedSetting = window.localStorage.getItem("handsFreeMode");
    if (savedSetting) {
      setHandsFreeMode(JSON.parse(savedSetting));
    } else {
      setHandsFreeMode(false);
    }
  }, [setHandsFreeMode]);

  useEffect(() => {
    console.log("handsFreeModeFlag", handsFreeModeFlag);
    if (isBrowser()) {
      if (!handsFreePlayback) {
        window.localStorage.removeItem("handsFreeMode");
      } else {
        if (handsFreeModeFlag) {
          getHandsFreeMode();
        }
      }
      window.addEventListener("storage", () => {
        getHandsFreeMode();
      });
    }
    return () => {
      window.removeEventListener("storage", () => {
        getHandsFreeMode();
      });
    };
  }, [handsFreeModeFlag, handsFreePlayback, getHandsFreeMode]);

  useEffect(() => {
    console.log("audioQueue", audioQueue);
    if (!audioElement) return;
    if (audioQueue?.length === 0) return;
    loadAudioSources();
    setActiveQueueItemId(audioQueue?.[0]?.itemId);
  }, [audioQueue, loadAudioSources]);

  useBeforeUnload(() => {
    resetQueue();
  });

  const audioState: AudioState = {
    activeQueueItemId,
    addToAudioQueue,
    audioElement,
    handsFreeModeEnabled,
    playbackEnabled,
    getPlayQueue,
    setQueue,
    pauseQueue,
    playQueue,
    queuePlaying,
    removeItemFromQueue,
    resetQueue,
    setHandsFreeMode,
    setPlaybackEnabled,
    setActiveQueueItemId,
    setQueuePlaying,
    setManualPlay,
  };

  return (
    <AudioAppContext.Provider value={audioState}>
      {children}
    </AudioAppContext.Provider>
  );
};

export default AudioAppContextProvider;
