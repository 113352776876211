import Feed from "@/components/Feed";
import Loading from "@/components/Loading/Index";
import NoRightsToViewContentModal from "@/components/NoRightsToViewContentModal";
import { useElectric } from "@/electric/ElectricWrapper";
import useLocationChange from "@/hooks/useLocationChange";
import { ActionContext } from "@/models/ActionsProvider";
import { AppContext } from "@/models/AppStateProvider";
import { DataContext } from "@/models/DataProvider";
import FeedContextProvider from "@/models/FeedContextProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { TrackingContext } from "@/models/TrackingStateProvider";
import { initialFeedLoad } from "@/models/actions/initialFeedLoad";
import { Stack } from "@mui/material";
import { is } from "date-fns/locale";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "regenerator-runtime/runtime";

export default function FeedId() {
  const {
    loadFeed,
    currentFeed,
    myAccountId,
    loadWorkspaceDetails,
    fetchWorkspaceMembership,
    checkFeedPermissions,
  } = useContext(DataContext);
  const { accountEvent } = useContext(ActionContext);
  const { ampli } = useContext(TrackingContext);
  // const { db } = useElectric();
  // const { client } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();
  const feedId = params?.feedId as string;
  const workspaceId = params?.workspaceId as string;
  const [invalidFeed, setInvalidFeed] = useState<boolean>(false);
  const [workspaceRedirect, setWorkspaceRedirect] = useState<string>(
    `/workspaces/${workspaceId}`,
  );

  useEffect(() => {
    if (history.scrollRestoration) {
      history.scrollRestoration = "manual";
    }
  }, []);

  // const closeModalAndRedirect = () => {
  //   navigate(workspaceRedirect);
  //   setInvalidFeed(() => false);
  // };

  const triggerOpenedFeed = useCallback(
    (feedId: string) => {
      accountEvent("Opened Feed", { feedId });
      ampli.feedOpen({ feedId });
    },
    [accountEvent],
  );

  // WEB-767: Set if a user is on a page, since removing their permissions does a check and re-joins them to a channel. If the user is on the page and their permissions are removed, they should be redirected to the next active channel.
  const [isUserOnPage, setIsUserOnPage] = useState<boolean>(false);

  useLocationChange(() => {
    setIsUserOnPage(() => false);
    setTimeout(() => {
      setIsUserOnPage(() => true);
    }, 3000);
  });

  const { fetchNextActiveChannel } = useContext(LiveQueryContext);

  useEffect(() => {
    console.log("fetching workspace membership", {
      myAccountId,
      workspaceId,
      feedId,
      isUserOnPage,
    });
    const f = async () => {
      await fetchWorkspaceMembership(myAccountId, workspaceId)
        .then((membership) => {
          setInvalidFeed(() => false);
          checkFeedPermissions({
            feedId,
            workspaceId,
            workspaceMembershipId: membership.id,
            isUserOnPage,
          })
            .then(() => {
              setInvalidFeed(() => false);
            })
            .catch(async (value) => {
              if (value && value === "redirect") {
                const redirectUrl = await fetchNextActiveChannel();
                return navigate(redirectUrl);
              } else {
                setWorkspaceRedirect(`/workspaces/${workspaceId}`);
                setInvalidFeed(() => true);
              }
            });
        })
        .catch(() => {
          setWorkspaceRedirect("/workspaces");
          setTimeout(() => {
            setInvalidFeed(() => true);
          }, 500);
        });
    };
    f();
  }, [
    workspaceId,
    feedId,
    myAccountId,
    isUserOnPage,
  ]);

  // useEffect(() => {
  //   const f = async () => {
  //     if (!myAccountId) return;
  //     loadFeed(feedId).catch(() => {
  //       setTimeout(() => {
  //         setInvalidFeed(() => true);
  //       }, 500);
  //     });
  //   };
  //   f();
  // }, [feedId, myAccountId, loadFeed]);

  useEffect(() => {
    if (feedId) {
      console.log("triggering feed open event", feedId);
      triggerOpenedFeed(feedId);
    }
  }, [feedId, triggerOpenedFeed]);

  // is this necessary? on every feed load?
  useEffect(() => {
    if (workspaceId) {
      loadWorkspaceDetails(workspaceId);
    }
  }, [workspaceId, loadWorkspaceDetails]);


  // this seems redudant? leaving out for now

  // On first load after bootstrap, the app doesn't know about feedId's, so it won't load via bootstrap
  // This checks to see if the data has been loaded yet, and if it hasn't it loads and marks as complete
  // useEffect(() => {
  //   const downloadAndUpdateFeed = async () => {
  //     await initialFeedLoad(client, db, feedId);
  //     await db.feed.update({
  //       where: {
  //         id: feedId,
  //       },
  //       data: {
  //         loadedFirstPage: 1,
  //       },
  //     });
  //   };
  //   if (feedId && currentFeed?.loadedFirstPage === 0) {
  //     downloadAndUpdateFeed();
  //   }
  // }, [client, db, feedId, currentFeed]);

  if (invalidFeed) {
    return <NoRightsToViewContentModal open={true} />;
  }


  return (
    <FeedContextProvider>
      {!feedId || !currentFeed ? (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </Stack>
      ) : (
        <Feed />
      )}
    </FeedContextProvider>
  );
}
