import {
  createManyWsAudioEncoding,
  createManyWsCallRecord,
  createManyWsDisplayArtifact,
  createManyWsEvent,
  createManyWsFile,
  createManyWsItem,
  createManyWsLink,
  createManyWsPAM,
  createManyWsPermission,
  createManyWsTranscription,
  getCommandAliasesByFeedId,
  getOtherFeedAccountsByFeedId,
  getWorkspaceMembershipRole,
} from "@/data/workspace";
import { Electric } from "@/generated/client";
import {
  GetWorkspaceFeedEventsResponse,
  GetWorkspaceFeedItemsResponse,
  GetWorkspaceFeedPermissionsResponse,
} from "web-client/api/data-contracts";
import Client from "web-client/client";
import { unreadClearEvents } from "../UnreadsContextProvider";

const ITEMS_PER_PAGE = 10;
const BOOTSTRAP_ITEMS_PER_PAGE = 10000;
const PERMISSIONS_PAGE_PAGE = 1000;
const EVENTS_PER_PAGE = 10000;

export async function initialFeedLoad(
  client: Client,
  db: Electric["db"],
  feedId: string,
): Promise<boolean> {
  const feed = await db.feed.findUnique({ where: { id: feedId } });
  if (!feed) {
    return Promise.reject(`Feed not found: ${feedId}`);
  }
  const workspaceId = feed?.workspaceId;
  if (feed.loadedFirstPage === 1 && feed.loadedEvents === 1) {
    console.log("ALREADY LOADED", feedId);
    return;
  }

  const startTime = Date.now();

  console.log("Initial load", feedId, startTime);

  // if (feed.loadedPermissions === 0) {
  // 	downloadPaginatedPermissions(client, db, workspaceId, feedId);
  // }

  if (feed.loadedFirstPage === 0) {
    const items = (
      await db.item.findMany({
        where: {
          feedId,
        },
        select: {
          id: true,
          createdAt: true,
          loadedContent: true,
        },
        orderBy: {
          createdAt: "desc",
        },
        take: 10,
      })
    ).filter((i) => i.loadedContent === 0);
    console.log("Loading first page from initial feed load", items.length);
    // if the refresh search param exist, wait for the download
    const refresh = new URLSearchParams(window.location.search).get("refresh");
    if (refresh === "true") {
      await downloadFeedItemContent(
        client,
        db,
        workspaceId,
        items.map((i) => i.id),
      );
    } else {
      downloadFeedItemContent(
        client,
        db,
        workspaceId,
        items.map((i) => i.id),
      );
    }
  }

  const updatedFeed = await db.feed.update({
    where: {
      id: feedId,
    },
    data: {
      loadedFirstPage: 1,
      loadedEvents: 1,
      loadedPermissions: 1,
    },
  });
  const duration = Date.now() - startTime;
  console.log("Initial load complete", feedId, duration / 1000);
  return updatedFeed?.loadedFirstPage === 1 && updatedFeed?.loadedEvents === 1;
}

export async function getNextFeedItemPage(
  client: Client,
  db: Electric["db"],
  feedId: string,
) {
  const feed = await db.feed.findUnique({ where: { id: feedId } });
  if (!feed) throw new Error("Feed not found");
  const workspaceId = feed?.workspaceId;

  if (feed.loadedLastPage === 0) {
    const itemCounts = await db.raw({
      sql: `select count(*) as itemCount from item where "feedId" = ?`,
      args: [feedId],
    });

    const itemCount = Number(itemCounts[0]?.itemCount || 0);
    const nextPage = Math.ceil(itemCount / ITEMS_PER_PAGE);
    return downloadPaginatedFeedItems(
      client,
      db,
      workspaceId,
      feedId,
      1,
      nextPage,
    );
  }
}

export async function downloadPaginatedBootstrapFeedItems(
  client: Client,
  db: Electric["db"],
  workspaceId: string,
  pagesToFetch = 100,
  startingPage = 0,
) {
  let page = startingPage;
  const parallelRequests = 1;
  let count = 0;

  while (page < startingPage + pagesToFetch) {
    const promises: Promise<GetWorkspaceFeedItemsResponse>[] = [];
    for (let index = 0; index < parallelRequests; index++) {
      const currentPage = page;
      promises.push(
        client
          .bootstrapWorkspaceFeedItems(
            workspaceId,
            currentPage,
            BOOTSTRAP_ITEMS_PER_PAGE,
          )
          .then(async (r) => {
            if (!r) return;
            createManyWsItem(db, r.items);
            return r;
          }),
      );
      page += 1;
    }
    const responses = await Promise.all(promises);
    const currentCount = responses.reduce(
      (acc, r) => acc + r?.items?.length,
      0,
    );
    count += currentCount;

    if (currentCount % BOOTSTRAP_ITEMS_PER_PAGE !== 0 || currentCount === 0) {
      return count;
    }

    if (page > 1000) {
      throw new Error("Too many pages");
    }
  }
  return count;
}

export async function downloadFeedItemContent(
  client: Client,
  db: Electric["db"],
  workspaceId: string,
  feedItemIds: string[],
) {
  if (feedItemIds.length === 0) return;
  const chunkSize = 5;
  const itemIdChunks = [];
  for (let i = 0; i < feedItemIds.length; i += chunkSize) {
    itemIdChunks.push(feedItemIds.slice(i, i + chunkSize));
  }

  for (const itemChunk of itemIdChunks) {
    client.getFeedItemContent(workspaceId, itemChunk).then((r) => {
      db.item.updateMany({
        where: {
          id: {
            in: feedItemIds,
          },
        },
        data: {
          loadedContent: 1,
        },
      });
      createManyWsTranscription(db, r?.transcriptions || []);
      createManyWsAudioEncoding(db, r?.audioEncodings || []);
      createManyWsFile(db, r?.files || []);
      createManyWsLink(db, r?.links || []);
      createManyWsDisplayArtifact(db, r?.displayArtifacts || []);
      createManyWsCallRecord(db, r?.callRecords || []);
      createManyWsPAM(db, r?.pam || []);
    });
  }
}

export async function downloadPaginatedFeedItems(
  client: Client,
  db: Electric["db"],
  workspaceId: string,
  feedId: string,
  pagesToFetch = 1,
  startingPage = 0,
) {
  let page = startingPage;
  const parallelRequests = 1;
  let count = 0;

  while (page < startingPage + pagesToFetch) {
    const promises: Promise<GetWorkspaceFeedItemsResponse>[] = [];
    for (let index = 0; index < parallelRequests; index++) {
      const currentPage = page;
      promises.push(
        client
          .getWorkspaceFeedItems(
            workspaceId,
            feedId,
            currentPage,
            ITEMS_PER_PAGE,
          )
          .then(async (r) => {
            if (!r) return;
            createManyWsItem(db, r.items);
            createManyWsAudioEncoding(db, r.audioEncodings);
            createManyWsFile(db, r.files);
            createManyWsLink(db, r.links);
            createManyWsTranscription(db, r.transcriptions);
            createManyWsDisplayArtifact(db, r.displayArtifacts);
            createManyWsEvent(db, r.events);
            createManyWsCallRecord(db, r.callRecords);
            createManyWsPAM(db, r.pam);
            return r;
          }),
      );
      page += 1;
    }
    const responses = await Promise.all(promises);
    const currentCount = responses.reduce((acc, r) => acc + r?.items.length, 0);
    count += currentCount;

    if (currentCount % ITEMS_PER_PAGE !== 0 || currentCount === 0) {
      return count;
    }

    if (page > 1000) {
      throw new Error("Too many pages");
    }
  }
  return count;
}

async function downloadPaginatedPermissions(
  client: Client,
  db: Electric["db"],
  workspaceId: string,
  feedId: string,
) {
  let page = 0;
  let count = 0;
  const parallelRequests = 1;

  while (true) {
    const promises: Promise<GetWorkspaceFeedPermissionsResponse>[] = [];

    for (let index = 0; index < parallelRequests; index++) {
      const currentPage = page;
      promises.push(
        client
          .getWorkspaceFeedPermissions(
            workspaceId,
            feedId,
            currentPage,
            PERMISSIONS_PAGE_PAGE,
          )
          .then(async (r) => {
            if (!r) return;
            createManyWsPermission(db, r.permissions);
            return r;
          }),
      );
      page += 1;
    }

    const responses = await Promise.all(promises);
    const currentCount = responses.reduce(
      (acc, r) => acc + r?.permissions.length,
      0,
    );
    count += currentCount;
    if (count % PERMISSIONS_PAGE_PAGE !== 0 || currentCount === 0) {
      return count;
    }

    if (page > 1000) {
      throw new Error("Too many pages");
    }
  }
}

export async function downloadPaginatedEvents(
  client: Client,
  db: Electric["db"],
  workspaceId: string,
  feedId: string,
) {
  let page = 0;
  let count = 0;
  const parallelRequests = 1;

  while (true) {
    const promises: Promise<GetWorkspaceFeedEventsResponse>[] = [];

    for (let index = 0; index < parallelRequests; index++) {
      const currentPage = page;
      promises.push(
        client
          .getWorkspaceFeedEvents(
            workspaceId,
            feedId,
            currentPage,
            EVENTS_PER_PAGE,
          )
          .then(async (r) => {
            if (!r) return;
            createManyWsEvent(db, r.events);
            return r;
          }),
      );
      page += 1;
    }

    const responses = await Promise.all(promises);
    const currentCount = responses.reduce(
      (acc, r) => acc + r?.events.length,
      0,
    );
    count += currentCount;
    if (currentCount % EVENTS_PER_PAGE !== 0 || currentCount === 0) {
      return count;
    }

    if (page > 1000) {
      throw new Error("Too many pages");
    }
  }
}

export async function downloadPaginatedLatestEvents(
  client: Client,
  db: Electric["db"],
  workspaceId: string,
  feedId: string,
) {
  let page = 0;
  let count = 0;
  const parallelRequests = 1;
  while (true) {
    const promises: Promise<GetWorkspaceFeedEventsResponse>[] = [];

    for (let index = 0; index < parallelRequests; index++) {
      const currentPage = page;
      promises.push(
        client
          .getWorkspaceLatestFeedEvents(
            workspaceId,
            feedId,
            currentPage,
            EVENTS_PER_PAGE,
          )
          .then(async (r) => {
            if (!r) return;
            await createManyWsEvent(db, r.events);
            return r;
          }),
      );
      page += 1;
    }

    const responses = await Promise.all(promises);
    const currentCount = responses.reduce(
      (acc, r) => acc + r?.events.length,
      0,
    );
    count += currentCount;
    if (currentCount % EVENTS_PER_PAGE !== 0 || currentCount === 0) {
      return count;
    }

    if (page > 1000) {
      throw new Error("Too many pages");
    }
  }
}

export async function downloadPaginatedOldestEvents(
  client: Client,
  db: Electric["db"],
  workspaceId: string,
  feedId: string,
) {
  let page = 0;
  let count = 0;
  const parallelRequests = 1;

  while (true) {
    const promises: Promise<GetWorkspaceFeedEventsResponse>[] = [];

    for (let index = 0; index < parallelRequests; index++) {
      const currentPage = page;
      promises.push(
        client
          .getWorkspaceOldestFeedEvents(
            workspaceId,
            feedId,
            currentPage,
            EVENTS_PER_PAGE,
          )
          .then(async (r) => {
            if (!r) return;
            await createManyWsEvent(db, r.events);
            return r;
          }),
      );
      page += 1;
    }

    const responses = await Promise.all(promises);
    const currentCount = responses.reduce(
      (acc, r) => acc + r?.events.length,
      0,
    );
    count += currentCount;
    if (currentCount % EVENTS_PER_PAGE !== 0 || currentCount === 0) {
      return count;
    }

    if (page > 1000) {
      throw new Error("Too many pages");
    }
  }
}

export async function initialUnreadItems(
  db: Electric["db"],
  feedId: string,
  myAccountId: string,
) {
  try {
    const feed = await db.feed.findUnique({ where: { id: feedId } });

    const commandAliases = await getCommandAliasesByFeedId(db, feedId);
    const isAliasChannel = commandAliases?.length > 0;

    const aliasAccounts =
      commandAliases
        ?.filter((ca) => ca && ca.accountId)
        ?.map((alias) => alias?.accountId as string) || [];

    const myCurrentWorkspaceRole = await getWorkspaceMembershipRole(
      db,
      myAccountId,
      { feedId },
    );

    const isOrganizer = myCurrentWorkspaceRole === "member" && isAliasChannel;

    const { otherOrganizers } = await getOtherFeedAccountsByFeedId(db, feedId);
    const mappedOrganizers = otherOrganizers?.map(
      (organizer) => organizer.accountId as string,
    );
    const allOrganizers =
      (isOrganizer
        ? mappedOrganizers?.concat(myAccountId)
        : mappedOrganizers) || [];

    const events = unreadClearEvents;

    const myLatestEvent = await db.account_event.findFirst({
      where: {
        accountId: myAccountId,
        feedId: feedId,
        name: { in: events },
      },
      orderBy: { createdAt: "desc" },
    });

    const myLatestDates = [
      myLatestEvent?.createdAt || "",
      feed?.lastOpened || "",
    ];

    const myLatest = myLatestDates.sort().reverse()[0];

    const organizerLatestEvent = await db.account_event.findFirst({
      where: {
        accountId: { in: allOrganizers },
        feedId: feedId,
        name: { in: events },
      },
      orderBy: { createdAt: "desc" },
    });

    const organizerLatestDates = [
      organizerLatestEvent?.createdAt || "",
      feed?.lastOpened || "",
    ];
    const organizerLatest = organizerLatestDates.sort().reverse()[0];

    if (isOrganizer && isAliasChannel) {
      return await db.item.updateMany({
        where: {
          feedId: feedId,
          accountId: { in: aliasAccounts },
          createdAt: { gte: organizerLatest },
          deletedAt: null,
        },
        data: {
          unread: 1,
        },
      });
    } else {
      return await db.item.updateMany({
        where: {
          feedId: feedId,
          accountId: { not: myAccountId },
          createdAt: { gte: myLatest },
          deletedAt: null,
        },
        data: {
          unread: 1,
        },
      });
    }
  } catch (e) {
    console.error(e);
  }
}
